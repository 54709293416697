import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";

import agent from "../../api/agent";
import React from "react";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import {
  DataGrid,
  GridRowsProp,
  GridRowModesModel,
  GridRowModes,
  GridColumns,
  GridRowParams,
  MuiEvent,
  GridToolbarContainer,
  GridActionsCellItem,
  GridEventListener,
  GridRowId,
  GridRowModel,
  GridValueFormatterParams,
} from "@mui/x-data-grid";
import { Button } from "@mui/material";
import { ProductVariationAdmin } from "models/product";
import { toast } from "react-toastify";

interface EditToolbarProps {
  setRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void;
  setRowModesModel: (
    newModel: (oldModel: GridRowModesModel) => GridRowModesModel
  ) => void;
}

interface Props {
  productId: number;
}

export default function Variations({ productId }: Props) {
  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState<GridRowsProp>([]);

  useEffect(()=> {
    setLoading(true);

    agent.Admin.variationList(productId)
      .then((list: ProductVariationAdmin[]) => {
        const v: GridRowsProp = list.map((variation: ProductVariationAdmin) => ({
          id: variation.id,
          onSale: variation.onSale,
          price: variation.price,
          priceAgent: variation.priceAgent,
          priceRetailer: variation.priceRetailer,
          priceWinkel: variation.priceWinkel,
          priceSale: variation.priceSale,
          productId: variation.productId,
          shippingWeight: variation.shippingWeight,
          title: variation.title,
          type: variation.type,
          sortOrder: variation.sortOrder,
        }));
        setRows(v);
      })
      .catch((error) => toast.error("Aggenee, dit het nie gewerk nie, probeer asb weer"))
      .finally(() => setLoading(false));

  }, [productId])

 
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>(
    {}
  );

  const handleRowEditStart = (
    params: GridRowParams,
    event: MuiEvent<React.SyntheticEvent>
  ) => {
    event.defaultMuiPrevented = true;
  };

  const handleRowEditStop: GridEventListener<"rowEditStop"> = (
    params,
    event
  ) => {
    event.defaultMuiPrevented = true;
  };

  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id: GridRowId) => () => {
    
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View },
    });
  };

  const handleDeleteClick = (id: GridRowId) => () => {
    setLoading(true);
    agent.Admin.deleteVariation(parseInt(id.toString()))
      .then((c) => setRows(rows.filter((row) => row.id !== id)))
      .catch((error) => {
       toast.error("Aggenee, dit het nie gewerk nie, probeer asb weer");
      })
      .finally(() => setLoading(false));
  };

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow!.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const handleProcessRowUpdateError = React.useCallback((error: Error) => {
    setLoading(false);
  }, []);

  const processRowUpdate = async (newRow: GridRowModel) => {
    setLoading(true);

    const rowincludingID = {...newRow, productId: productId};
   // console.log('including id', rowincludingID);

    //Save to database. Insert new if ID is 0.
    if (newRow.id === 0) {
      const result = await agent.Admin.createVariation(rowincludingID);
      setLoading(false);

      const updatedRow = { ...newRow, isNew: false, id: result.id };
      setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
      return updatedRow;

    } else {
      const result = await agent.Admin.updateVariation(rowincludingID);
      setLoading(false);

      const updatedRow = { ...newRow, isNew: false, id: result.id };
      setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
      return updatedRow;

    }

  
  };

  function EditToolbar(props: EditToolbarProps) {
    const { setRows, setRowModesModel } = props;

    const handleClick = () => {
      const blankrow = {
        id: 0,
        onSale: false,
        price: 0,
        priceAgent: 0,
        priceRetailer: 0,
        priceWinkel: 0,
        priceSale: 0,
        productId: productId,
        shippingWeight: 0,
        title: '',
        type: 'Geur',
        isNew: true,
        sortOrder: rows.length + 1
      };

      setRows((oldRows) => [...oldRows, blankrow]);

      setRowModesModel((oldModel) => ({
        ...oldModel,
        [blankrow.id]: { mode: GridRowModes.Edit, fieldToFocus: "title" },
      }));
    };

    return (
      <GridToolbarContainer>
        <Button
          color="primary"
          startIcon={<AddIcon />}
          onClick={handleClick}
          disabled={rows.find((x) => x.id === 0) !== undefined}
        >
          Skep Nuwe
        </Button>
      </GridToolbarContainer>
    );
  }

  // id: 0,
  // onSale: false,
  // price: 0,
  // priceAgent: 0,
  // priceSale: 0,
  // shippingWeight: 0,

  const currencyFormatter = new Intl.NumberFormat('en-ZA', {
    style: 'currency',
    currency: 'ZAR',
  });


  const columns: GridColumns = [
    {
      field: "type",
      headerName: "Soort",
      width: 120,
      type: "singleSelect",
      valueOptions: ["Geur", "Grootte", "Kleur", "Opsie 1", "Opsie 2", "Opsie 3", "Opsie 4", "Opsie 5", "Item 1", "Item 2", "Item 3", "Item 4", "Item 5"],
      editable: true,
    },
    {
      field: "title",
      headerName: "Titel",
      width: 180,
      editable: true,
    },
    {
      field: "price",
      headerName: "Prys",
      width: 120,
      editable: true,
      type: "number",
      valueFormatter: ({ value }) => currencyFormatter.format(Number(value)),
    },
    {
      field: "priceAgent",
      headerName: "Prys Agent",
      width: 120,
      editable: true,
      type: "number",
      valueFormatter: ({ value }) => currencyFormatter.format(Number(value)),
    },
    {
      field: "priceRetailer",
      headerName: "Prys Retailer",
      width: 120,
      editable: true,
      type: "number",
      valueFormatter: ({ value }) => currencyFormatter.format(Number(value)),
    },
    {
      field: "priceWinkel",
      headerName: "Prys Winkel",
      width: 120,
      editable: false,
      type: "number",
      valueGetter: (params) => currencyFormatter.format(Number(params.row.price * 0.70)) ,
      //valueFormatter: ({ value }) => currencyFormatter.format(Number(value)),
    },
    {
      field: "priceSale",
      headerName: "Prys Uitverkoping",
      width: 120,
      editable: true,
      type: "number",
      valueFormatter: ({ value }) => currencyFormatter.format(Number(value)),
    },
    {
      field: "onSale",
      headerName: "Op Uitverkoping?",
      width: 150,
      type: "boolean",
      editable: true,
    },
    {
      field: "shippingWeight",
      headerName: "Posgewig (gram)",
      width: 150,
      editable: true,
      type: "number",
      valueFormatter: (params: GridValueFormatterParams<number>) => {
        if (params.value == null) {
          return '0 g';
        }

        const valueFormatted = Number(params.value).toLocaleString();
        return `${valueFormatted} g`;
      },
    },
    {
      field: "sortOrder",
      headerName: "Sorteer Volgens",
      width: 120,
      editable: true,
      type: "number"
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Opsies",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  return (
    
       
       

          <Box
            sx={{
              paddingTop: 3,
              height: 700,
              width: "100%",
              "& .actions": {
                color: "text.secondary",
              },
              "& .textPrimary": {
                color: "text.primary",
              },
            }}
          >
            <DataGrid
              rows={rows}
              columns={columns}
              editMode="row"
              disableSelectionOnClick
              rowModesModel={rowModesModel}
              onRowModesModelChange={(newModel) => setRowModesModel(newModel)}
              onRowEditStart={handleRowEditStart}
              onRowEditStop={handleRowEditStop}
              processRowUpdate={processRowUpdate}
              onProcessRowUpdateError={handleProcessRowUpdateError}
              components={{
                Toolbar: EditToolbar,
                LoadingOverlay: LinearProgress,
              }}
              componentsProps={{
                toolbar: { setRows, setRowModesModel },
              }}
              experimentalFeatures={{ newEditingApi: true }}
              loading={loading}
            />
          </Box>
       
   
  );
}
