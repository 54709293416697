import { useFormik } from "formik";
import * as yup from "yup";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { ProductAdmin, ProductImage } from "models/product";
import { LoadingButton } from "@mui/lab";
import { toCamel } from "util/util";
import CalculateIcon from "@mui/icons-material/Calculate";

import DeleteIcon from "@mui/icons-material/Delete";
import CenterFocusWeakIcon from "@mui/icons-material/CenterFocusWeak";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";

import {
  MenuItem,
  Checkbox,
  Stack,
  Typography,
  Skeleton,
  IconButton,
  Tab,
  Tabs,
  InputAdornment,
} from "@mui/material";
import agent from "api/agent";
import { slugify } from "util/util";
import { useState } from "react";
import { toast } from "react-toastify";
import { useAppSelector } from "store/configureStore";
import Variations from "./Variations";

const validationSchema = yup.object({
  name: yup
    .string()
    .trim()
    .min(2, "Naam moet tenminste 2 karakters wees")
    .max(100, "Naam moet minder as 100 karakters wees")
    .required("Naam is 'n noodsaaklike veld."),
  slug: yup
    .string()
    .trim()
    .min(2, "Slug moet tenminste 2 karakters wees")
    .max(100, "Slug moet minder as 100 karakters wees")
    .required("Slug is 'n noodsaaklike veld."),
  description: yup
    .string()
    .trim()
    .required("Beskrywing is 'n noodsaaklike veld.")
    .max(500, "Beskrywing moet minder as 500 karakters wees."),
});

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

interface Props {
  product: ProductAdmin;
  onClose: () => void;
}

const ProductDetails = ({ product, onClose }: Props): JSX.Element => {
  const { adminBrands, adminCategories } = useAppSelector(
    (state) => state.catalog
  );

  const [loadingImages, setLoadingImages] = useState(false);
  const [images, setImages] = useState<ProductImage[]>(
    product?.productImages ?? []
  );

  const [tabValue, setTabValue] = useState(0);

  // useEffect(() => {
  //   setImages(product?.productImages ?? []);
  //   setLoadingImages(false);

  // }, [product?.productImages]);

  const deleteImage = async (id: number) => {
    try {
      setLoadingImages(true);

      await agent.Admin.deleteProductImage(id);

      setImages((images) => images.filter((item) => item.id !== id));

      toast.success("Photo deleted");

      setLoadingImages(false);
    } catch (errors) {
      setLoadingImages(false);

      toast.error("Aggenee, dit het nie gewerk nie, probeer asb weer");
    }
  };

  const setMainImage = async (id: number) => {
    try {
      setLoadingImages(true);

      var newimages = await agent.Admin.setMainProductImage(id);

      setImages(newimages);

      toast.success("Main photo set");

      setLoadingImages(false);
    } catch (errors) {
      setLoadingImages(false);

      toast.error("Aggenee, dit het nie gewerk nie, probeer asb weer");
    }
  };

  const uploadFile = async (e) => {
    try {
      setLoadingImages(true);

      var photo = await agent.Admin.addProductImage({
        file: e.target.files[0],
        productId: product.id,
      });

      //add to image list.
      setImages((images) => [...images, photo]);

      toast.success("Foto suksesvol opgelaai");

      setLoadingImages(false);
    } catch (errors) {
      setLoadingImages(false);

      toast.error("Aggenee, dit het nie gewerk nie, probeer asb weer");
    }
  };

  const initialValues = {
    id: product?.id,
    name: product?.name ?? "",
    slug: product?.slug ?? "",
    description: product?.description ?? "",
    price: (product?.price).toFixed(2) ?? "0.00",
    priceRetailer: (product?.priceRetailer).toFixed(2) ?? "0.00",
    priceAgent: (product?.priceAgent).toFixed(2) ?? "0.00",
    priceSale: (product?.priceSale).toFixed(2) ?? "0.00",
    priceWinkel: (product?.priceWinkel).toFixed(2) ?? "0.00",
    onSale: product?.onSale ?? false,
    productCategoryId: product?.productCategoryId ?? 1,
    productBrandId: product?.productBrandId ?? 1,
    quantityInStock: product?.quantityInStock ?? 0,
    stockType: product?.stockType ?? "Onbeperk",
    showStock: product?.showStock ?? false,
    productVariations: product?.productVariations ?? null,
    status: product?.status ?? "Draft",
    isFeatured: product?.isFeatured ?? false,
    shippingWeight: product?.shippingWeight ?? 0,
    visibleAdmin: product?.visibleAdmin ?? true,
    visibleRetailer: product?.visibleRetailer ?? true,
    visibleAgent: product?.visibleAgent ?? true,
    visibleGewoon: product?.visibleGewoon ?? true,
    visiblePublic: product?.visiblePublic ?? true,
    visibleWinkel: product?.visibleWinkel ?? true,
    commissionExempt: product?.commissionExempt ?? false,
  };

  async function onSubmit(values) {
    try {
      if (product) {
        await agent.Admin.updateProduct(values);
      } else {
        await agent.Admin.createProduct(values);
      }

      onClose();
    } catch (errors) {
      formik.setErrors(toCamel(errors));

      const errorslist: string[] = [];

      if (Object.keys(errors).length > 0) {
        Object.values(errors)
          .flat()
          .forEach((err: any, i) => {
            errorslist.push(err);
          });
      }
    }
  }

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit,
  });

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  if (product === null || product === undefined) return null;

  return (
    <Box>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            aria-label="basic tabs example"
          >
            <Tab label="Algemeen" {...a11yProps(0)} />
            <Tab label="Variasies" {...a11yProps(1)} />
            <Tab label="Fotos" {...a11yProps(2)} />
          </Tabs>
        </Box>
        <TabPanel value={tabValue} index={0}>
          <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <strong>Algemeen:</strong>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Naam"
                  variant="outlined"
                  name={"name"}
                  fullWidth
                  value={formik.values.name}
                  onChange={(e) => {
                    formik.setFieldValue(e.target.name, e.target.value, true);
                    formik.setFieldValue("slug", slugify(e.target.value), true);
                  }}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  // @ts-ignore
                  helperText={formik.touched.name && formik.errors.name}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Slug"
                  variant="outlined"
                  name={"slug"}
                  fullWidth
                  value={formik.values.slug}
                  onChange={(e) =>
                    formik.setFieldValue(
                      e.target.name,
                      slugify(e.target.value),
                      true
                    )
                  }
                  error={formik.touched.slug && Boolean(formik.errors.slug)}
                  // @ts-ignore
                  helperText={formik.touched.slug && formik.errors.slug}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  label="Beskrywing"
                  variant="outlined"
                  name={"description"}
                  fullWidth
                  rows={3}
                  multiline
                  value={formik.values.description}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.description &&
                    Boolean(formik.errors.description)
                  }
                  // @ts-ignore
                  helperText={
                    formik.touched.description && formik.errors.description
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Status"
                  variant="outlined"
                  name={"status"}
                  select
                  fullWidth
                  value={formik.values.status}
                  onChange={formik.handleChange}
                  error={formik.touched.status && Boolean(formik.errors.status)}
                  // @ts-ignore
                  helperText={formik.touched.status && formik.errors.status}
                >
                  <MenuItem value={"Draft"}>Draft</MenuItem>
                  <MenuItem value={"Versteek"}>Versteek</MenuItem>
                  <MenuItem value={"Sigbaar"}>Sigbaar</MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Posgewig (gram)"
                  variant="outlined"
                  name={"shippingWeight"}
                  type="number"
                  fullWidth
                  value={formik.values.shippingWeight}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.shippingWeight &&
                    Boolean(formik.errors.shippingWeight)
                  }
                  // @ts-ignore
                  helperText={
                    formik.touched.shippingWeight &&
                    formik.errors.shippingWeight
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Checkbox
                  value={formik.values.commissionExempt}
                  name={"commissionExempt"}
                  checked={formik.values.commissionExempt}
                  onChange={formik.handleChange}
                />{" "}
                Kommissie Uitgesluit (Agente kry nie kommissie op die produk)
              </Grid>
             
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <strong>Katalogus:</strong>
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  label="Kategorie"
                  variant="outlined"
                  name={"productCategoryId"}
                  select
                  fullWidth
                  value={formik.values.productCategoryId}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.productCategoryId &&
                    Boolean(formik.errors.productCategoryId)
                  }
                  // @ts-ignore
                  helperText={
                    formik.touched.productCategoryId &&
                    formik.errors.productCategoryId
                  }
                >
                  {/* <MenuItem key={0} value={0}>
                Kies...
              </MenuItem> */}
                  {adminCategories.map((category) => (
                    <MenuItem key={category.id} value={category.id}>
                      {category.title}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  label="Reeks"
                  variant="outlined"
                  name={"productBrandId"}
                  select
                  fullWidth
                  value={formik.values.productBrandId}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.productBrandId &&
                    Boolean(formik.errors.productBrandId)
                  }
                  // @ts-ignore
                  helperText={
                    formik.touched.productBrandId &&
                    formik.errors.productBrandId
                  }
                >
                  {/* <MenuItem key={0} value={0}>
                Kies...
              </MenuItem> */}
                  {adminBrands.map((brand) => (
                    <MenuItem key={brand.id} value={brand.id}>
                      {brand.title}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Checkbox
                  value={formik.values.isFeatured}
                  name={"isFeatured"}
                  checked={formik.values.isFeatured}
                  onChange={formik.handleChange}
                />{" "}
                Voorskou Produk? (Vertoon op tuisblad)
              </Grid>

              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <strong>Pryse:</strong>
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  label="Prys"
                  variant="outlined"
                  name={"price"}
                  fullWidth
                  type="number"
                  value={formik.values.price}
                  onChange={(e) => {
                    formik.handleChange(e);
                    formik.setFieldValue(
                      "priceAgent",
                      (parseFloat(e.target.value) * 0.8).toFixed(2),
                      true
                    );
                    formik.setFieldValue(
                      "priceRetailer",
                      (parseFloat(e.target.value) * 0.75).toFixed(2),
                      true
                    );
                    formik.setFieldValue(
                      "priceWinkel",
                      (parseFloat(e.target.value) * 0.70).toFixed(2),
                      true
                    );
                  }}
                  error={formik.touched.price && Boolean(formik.errors.price)}
                  // @ts-ignore
                  helperText={formik.touched.price && formik.errors.price}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  label="Prys Agent"
                  variant="outlined"
                  name={"priceAgent"}
                  fullWidth
                  type="number"
                  value={formik.values.priceAgent}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.priceAgent &&
                    Boolean(formik.errors.priceAgent)
                  }
                  // @ts-ignore
                  helperText={
                    formik.touched.priceAgent && formik.errors.priceAgent
                  }
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() =>
                            formik.setFieldValue(
                              "priceAgent",
                              (formik.values.price * 0.8).toFixed(2),
                              true
                            )
                          }
                          edge="start"
                        >
                          <CalculateIcon />
                        </IconButton>
                        (
                        <Typography>
                          {(
                            (formik.values.priceAgent / formik.values.price -
                              1) *
                            100
                          ).toFixed(2)}
                          %
                        </Typography>
                        )
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  label="Prys Retailer"
                  variant="outlined"
                  name={"priceRetailer"}
                  fullWidth
                  type="number"
                  value={formik.values.priceRetailer}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.priceRetailer &&
                    Boolean(formik.errors.priceRetailer)
                  }
                  // @ts-ignore
                  helperText={
                    formik.touched.priceRetailer && formik.errors.priceRetailer
                  }
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() =>
                            formik.setFieldValue(
                              "priceRetailer",
                              (formik.values.price * 0.75).toFixed(2),
                              true
                            )
                          }
                          edge="start"
                        >
                          <CalculateIcon />
                        </IconButton>
                        (
                        <Typography>
                          {(
                            (formik.values.priceRetailer / formik.values.price -
                              1) *
                            100
                          ).toFixed(2)}
                          %
                        </Typography>
                        )
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  label="Prys Winkel"
                  variant="outlined"
                  name={"priceWinkel"}
                  fullWidth
                  type="number"
                  value={formik.values.priceWinkel}
                  //onChange={formik.handleChange}
                  disabled
                  error={
                    formik.touched.priceWinkel &&
                    Boolean(formik.errors.priceWinkel)
                  }
                  // @ts-ignore
                  helperText={
                    formik.touched.priceWinkel && formik.errors.priceWinkel
                  }
                  
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Prys Uitverkoping"
                  variant="outlined"
                  name={"priceSale"}
                  fullWidth
                  type="number"
                  value={formik.values.priceSale}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.priceSale && Boolean(formik.errors.priceSale)
                  }
                  // @ts-ignore
                  helperText={
                    formik.touched.priceSale && formik.errors.priceSale
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Checkbox
                  //value={formik.values.onSale}
                  name={"onSale"}
                  checked={formik.values.onSale}
                  onChange={formik.handleChange}
                />{" "}
                Op Uitverkoping?
              </Grid>

              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <strong>Voorraad:</strong>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Hoeveelheid in Voorraad"
                  variant="outlined"
                  name={"quantityInStock"}
                  fullWidth
                  type="number"
                  value={formik.values.quantityInStock}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.quantityInStock &&
                    Boolean(formik.errors.quantityInStock)
                  }
                  // @ts-ignore
                  helperText={
                    formik.touched.quantityInStock &&
                    formik.errors.quantityInStock
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Checkbox
                  value={formik.values.showStock}
                  name={"showStock"}
                  checked={formik.values.showStock}
                  onChange={formik.handleChange}
                />{" "}
                Wys voorraad hoeveelheid in winkel?
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  label="Vooraad Tiepe"
                  variant="outlined"
                  name={"stockType"}
                  select
                  fullWidth
                  value={formik.values.stockType}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.stockType && Boolean(formik.errors.stockType)
                  }
                  // @ts-ignore
                  helperText={
                    formik.touched.stockType && formik.errors.stockType
                  }
                >
                  <MenuItem value={"Onbeperk"}>Onbeperk</MenuItem>
                  <MenuItem value={"Beperk"}>Beperk</MenuItem>
                </TextField>
              </Grid>

              <Grid item xs={12}>
                <Divider />
              </Grid>

              <Grid item xs={12}>
                <strong>Sigbaar:</strong>
              </Grid>
              <Grid item xs={3} sm={2}>
                <Checkbox
                  name={"visibleAdmin"}
                  checked={formik.values.visibleAdmin}
                  onChange={formik.handleChange}
                />{" "}
               Admin
              </Grid>
              <Grid item xs={3} sm={2}>
                <Checkbox
                  name={"visibleAgent"}
                  checked={formik.values.visibleAgent}
                  onChange={formik.handleChange}
                />{" "}
               Agent
              </Grid>
              <Grid item xs={3} sm={2}>
                <Checkbox
                  name={"visibleRetailer"}
                  checked={formik.values.visibleRetailer}
                  onChange={formik.handleChange}
                />{" "}
               Retailer
                          </Grid>
                          <Grid item xs={3} sm={2}>
                              <Checkbox
                                  name={"visibleWinkel"}
                                  checked={formik.values.visibleWinkel}
                                  onChange={formik.handleChange}
                              />{" "}
                              Winkel
                          </Grid>
              <Grid item xs={3} sm={2}>
                <Checkbox
                  name={"visibleGewoon"}
                  checked={formik.values.visibleGewoon}
                  onChange={formik.handleChange}
                />{" "}
               Gewoon
              </Grid>
              <Grid item xs={3} sm={2}>
                <Checkbox
                  name={"visiblePublic"}
                  checked={formik.values.visiblePublic}
                  onChange={formik.handleChange}
                />{" "}
               Publiek
              </Grid>


              <Grid item xs={12}>
                <Divider />
              </Grid>

              <Grid item container xs={12}>
                <Box
                  display="flex"
                  flexDirection={{ xs: "column", sm: "row" }}
                  alignItems={{ xs: "stretched", sm: "center" }}
                  justifyContent={"space-between"}
                  width={1}
                  margin={"0 auto"}
                >
                  <Button onClick={onClose} variant="contained" color="inherit">
                    Cancel
                  </Button>

                  <LoadingButton
                    type="submit"
                    disabled={formik.isSubmitting}
                    loading={formik.isSubmitting}
                    variant="contained"
                    color="success"
                  >
                    Stoor
                  </LoadingButton>
                </Box>
              </Grid>
            </Grid>
          </form>
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <Grid container>
            <Grid item xs={12} sm={12}>
              <strong>Variasies:</strong>
              <Variations productId={product.id} />
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value={tabValue} index={2}>
          <Grid container>
            <Grid item xs={12}>
              <strong>Fotos:</strong>
            </Grid>
            <Grid item xs={12}>
              {loadingImages && (
                <Box>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Skeleton variant="rectangular" height={50} />
                    </Grid>
                    <Grid item xs={3}>
                      <Skeleton variant="rectangular" height={150} />
                      <Skeleton
                        variant="rectangular"
                        height={50}
                        sx={{ marginTop: 2 }}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <Skeleton variant="rectangular" height={150} />
                      <Skeleton
                        variant="rectangular"
                        height={50}
                        sx={{ marginTop: 2 }}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <Skeleton variant="rectangular" height={150} />
                      <Skeleton
                        variant="rectangular"
                        height={50}
                        sx={{ marginTop: 2 }}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <Skeleton variant="rectangular" height={150} />
                      <Skeleton
                        variant="rectangular"
                        height={50}
                        sx={{ marginTop: 2 }}
                      />
                    </Grid>
                  </Grid>
                </Box>
              )}

              {!loadingImages && (
                <>
                  <Box sx={{ padding: 2 }}>
                    {/* <Button variant="contained" component="label">
  Upload Photo
  <input hidden accept="image/*" multiple={false} type="file" onChange={saveFile}/>
</Button> */}
                    <IconButton
                      sx={{
                        padding: 5,
                        width: "100%",
                        border: "solid 1px",
                        borderRadius: 1,
                      }}
                      color="primary"
                      aria-label="save photo"
                      component="label"
                    >
                      <input
                        hidden
                        accept="image/*"
                        multiple={false}
                        type="file"
                        onChange={uploadFile}
                      />
                      <AddPhotoAlternateIcon />{" "}
                      <Typography fontSize={13}>Laai foto</Typography>
                    </IconButton>

                    {/* <input type={"file"} multiple={false} onChange={saveFile} /> */}
                    {/* <Button
                    variant={"contained"}
                    color={"primary"}
                    size={"small"}
                    onClick={uploadFile}
                  >
                    Upload
                  </Button> */}
                  </Box>

                  {images.length === 0 && (
                    <Typography fontSize={15}>
                      Die produk het nog nie enige fotos nie. Gebruik die boks
                      bo om fotos op te laai.
                    </Typography>
                  )}

                  <Grid container spacing={2} sx={{ height: 1, marginTop: 2 }}>
                    {images.map((item, i) => (
                      <Grid key={i} item xs={4} md={3} lg={2}>
                        <Box
                          sx={{
                            border: "solid 1px lightgrey",
                            borderRadius: 2,
                            padding: 1,
                          }}
                        >
                          <Box
                            sx={{
                              // display: "flex",
                              // height: 1,
                              "& img": {
                                width: 1,
                                height: 1,
                                // objectFit: "cover",
                                borderRadius: 1,
                              },
                            }}
                          >
                            <img
                              src={"/uploads/" + item.thumbnailUrl}
                              width={100}
                              alt={item.originalFileName}
                              loading={"lazy"}
                            />
                          </Box>
                          <Stack marginTop={2} spacing={1} direction={"row"}>
                            {/* <Button
                            variant={"contained"}
                            color={"error"}
                            size={"small"}
                            onClick={() => deleteImage(item.id)}
                          >
                            Delete
                          </Button> */}
                            <IconButton
                              color="error"
                              aria-label="delete photo"
                              component="label"
                              onClick={() => deleteImage(item.id)}
                            >
                              <DeleteIcon />
                            </IconButton>

                            {!item.isMain && (
                              <IconButton
                                color="primary"
                                aria-label="Set as Main Image"
                                component="label"
                                onClick={() => setMainImage(item.id)}
                              >
                                <CenterFocusWeakIcon />
                              </IconButton>
                              // <Button
                              //   variant={"contained"}
                              //   color={"primary"}
                              //   size={"small"}
                              //   onClick={() => setMainImage(item.id)}
                              // >
                              //   Set Main
                              // </Button>
                            )}
                          </Stack>
                          <Box sx={{ paddingTop: 2 }}>
                            <Typography fontSize={12}>
                              Size: {(item.size / 1024).toFixed(0)}KB
                            </Typography>
                            <Typography fontSize={12}>
                              Name: {item.originalFileName}
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
        </TabPanel>
      
      </Box>
    </Box>
  );
};

export default ProductDetails;
