import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";

import agent from "../../../api/agent";
import {
  DataGrid,
  GridRowsProp,
  GridColumns,
  GridValueFormatterParams,
} from "@mui/x-data-grid";
import { toast } from "react-toastify";
import { ChallengeOrder } from "models/challenge";

export default function ChallengeOrdersList() {
  const [loading, setLoading] = useState(true);

  const [rows, setRows] = useState<GridRowsProp>([]);

  useEffect(() => {
    agent.Challenge.getUserChallengeOrders()
      .then((list: ChallengeOrder[]) => {
        const pointlist: GridRowsProp = list.map((item, index) => ({
          orderId: item.orderId,
          orderDate: item.orderDate,
          orderStatus: item.orderStatus,
          quantity: item.quantity,
        }));
        setRows(pointlist);
      })
      .catch((error) =>
        toast.error("Aggenee, dit het nie gewerk nie, probeer asb weer")
      )
      .finally(() => setLoading(false));
  }, []);

  const columns: GridColumns = [
 
    { field: "orderId", headerName: "Bestelling No", width: 120 },
    { field: "orderStatus", headerName: "Status", width: 170 },
    {
      field: "orderDate",
      headerName: "Datum",
      width: 180,
      valueFormatter: (params: GridValueFormatterParams<Date>) => {
        if (params.value == null) {
          return "";
        }

        const date = new Date(params.value);

        const valueFormatted = date.toLocaleDateString("en-ZA", {
          year: "numeric",
          month: "short",
          day: "numeric"
        });
        return valueFormatted;
      },
    },
    {
      field: "quantity",
      headerName: "Hoeveelheid Sakkies",
      width: 250,
    },
    
  ];

  return (
    <Box
      sx={{
        height: 500,
        width: "100%",
        "& .actions": {
          color: "text.secondary",
        },
        "& .textPrimary": {
          color: "text.primary",
        },
      }}
    >
      <DataGrid
        rows={rows}
        columns={columns}
        disableSelectionOnClick
        components={{
          LoadingOverlay: LinearProgress,
        }}
        loading={loading}
      />
    </Box>
  );
}
