import FixedLayout from "layouts/Fixed";
import {
  Typography,
  Box,
  LinearProgress,
} from "@mui/material";
import { useEffect, useState } from "react";
import agent from "../../api/agent";
import {
  DataGrid,
  GridColumns,
  GridRowsProp,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarExport,
  GridValueFormatterParams,
} from "@mui/x-data-grid";
import React from "react";
//import ProductQuickViewDialog from "./ProductQuickViewDialog";
import { toast } from "react-toastify";
import { CommissionEarned } from "models/commission";


const CommissionEarningsList = (): JSX.Element => {
  const [rows, setRows] = useState<GridRowsProp>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    agent.Admin.commissionEarnedList()
      .then((c: CommissionEarned[]) => {
        const s: GridRowsProp = c.map((entry) => ({
          id: entry.id,
          orderId: entry.orderId,
          orderItemId: entry.orderItemId,
          userId: entry.userId,
          userEmail: entry.userEmail,
          commissionPercentage: entry.commissionPercentage,
          commissionAmount: entry.commissionAmount,
          taxDeductionAmount: entry.taxDeductionAmount,
          finalAmount: entry.finalAmount,
          level: entry.level,
          dateProcessed: entry.dateProcessed,
          status: entry.status,
          potentialAmountA: entry.potentialAmountA,
          potentialAmountB: entry.potentialAmountB,
        }));
        setRows(s);
      })
      .catch((error) => {
        toast.error("Aggenee, dit wou nie laai nie. Probeer asb weer");
      })
      .finally(() => setLoading(false));
  }, []);

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }


  const columns: GridColumns = [
    {
      field: "id",
      headerName: "Id",
      width: 50,
    },
    {
      field: "orderId",
      headerName: "Bestelling No",
      width: 100,
    },
    {
      field: "orderItemId",
      headerName: "Produk No",
      width: 100,
    },
    {
      field: "userEmail",
      headerName: "Kommissie Ontvanger",
      width: 200,
    },
    {
      field: "level",
      headerName: "Diepte",
      width: 100,
    },
    {
      field: "potentialAmountA",
      headerName: "Vlak A Kommissie R",
      width: 200,
    },
    {
      field: "potentialAmountB",
      headerName: "Vlak B Kommissie R",
      width: 200,
    },
    {
      field: "finalAmount",
      headerName: "Finale Kommissie R",
      width: 200,
    },
    {
      field: "dateProcessed",
      headerName: "Datum",
      width: 130,
      valueFormatter: (params: GridValueFormatterParams<Date>) => {
        if (params.value == null) {
          return "";
        }

        const date = new Date(params.value);

        const valueFormatted = date.toLocaleDateString("en-ZA", {
          year: "numeric",
          month: "short",
          day: "numeric",
        });
        return valueFormatted;
      },
    },
    {
      field: "status",
      headerName: "Status",
      width: 200,
    }
  ];

  return (
    <FixedLayout>
      <Box
        width={1}
        margin={"0 auto"}
        paddingX={2}
        paddingY={{ xs: 2, sm: 2, md: 2 }}
      >
        <Box display="flex" justifyContent="space-between">
          <Typography sx={{ p: 2 }} variant="h5">
            Verdienste
          </Typography>
        </Box>

        <div style={{ height: 600, width: "100%" }}>
       
          <DataGrid
            rows={rows}
            columns={columns}
            components={{
              Toolbar: CustomToolbar,
              LoadingOverlay: LinearProgress,
            }}
            loading={loading}
          />
        </div>

      

        
      </Box>
    </FixedLayout>
  );
};

export default CommissionEarningsList;
